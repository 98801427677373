<template>
  <div class="page-container message-page">
    <!--列表-->
    <div class="lists" v-if="lists.list.length>0">
      <div class="li"  v-for="(item,index) in lists.list" @click="ListMethods().clickViewBtn(item,index)">
        <div class="title ellipsis">
          <el-tag :type="!item.hasRead?'warning':'info'" size="small" style="margin-right: 10px">
            {{ item.hasRead ? "已读" : "未读" }}
          </el-tag>
          {{ item.title }}
        </div>
        <div class="info flex flex-start">
          <span class="type">{{ item.type }}</span>
          <span class="divider">|</span>
          <span class="date">{{ item.createTime |dateFormat }}</span>
        </div>
      </div>
    </div>
    <div class="no-list" v-if="lists.list.length===0" style="text-align: center;padding: 50px 0px;color: #666">
      <i class="el-icon-warning" style="margin-right: 10px"></i>
      <span>暂无消息</span>
    </div>
    <!--列表分页-->
    <div class="pagination-container" v-if="lists.list.length>0">
      <el-pagination background @current-change="(number)=>ListMethods().pageChange(number)"
                     :current-page.sync="lists.pages.number" :page-size.sync="lists.pages.size"
                     layout="total,prev, pager, next,sizes" :total="lists.pages.totalElements"
                     @size-change="(size)=>ListMethods().pageLimitChange(size)"
                     :page-count="lists.pages.totalPages">
      </el-pagination>
    </div>

    <!--详情弹窗-班级详情-->
    <el-dialog
        title="消息详情"
        :visible.sync="entityInfo.dialog"
        width="900px"
        center
        v-el-drag-dialog>
      <div class="dialog-container message-dialog">
        <div class="title">{{ entityInfo.info.title }}</div>
        <div class="type">{{ entityInfo.info.type }}</div>
        <div v-html="entityInfo.info.content" class="html-view content limit-height"></div>
        <div class="date">{{ entityInfo.info.createTime | dateFormat }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {KnowConfigModel} from "@/model/exp/KnowConfigModel";
import {MessageModel} from "@/model/exp/MessageModel";
import {mapState} from "vuex";
import {dateFormat, numberFormat} from "@/filter/filter";
import elDragDialog from "@/directive/el-drag-dialog";
import {PLATFORM_ID} from "@/model/ConfigModel";

export default {
  name: "message",
  // 过滤器
  filters: {
    dateFormat: dateFormat
  },
  directives: {
    elDragDialog
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      activeName: "message",
      knowContent: "",
      userUnionId: "0",
      notReadNum: 0,
      // 列表
      lists: {
        list: [],
        loading: false,
        query: {},
        queryBase: {},
        pages: {
          size: 10
        },
        searchFilter: {
          search: [],
          filter: []
        }
      },
      entityInfo: {
        dialog: false,
        info: {}
      }
    }
  },
  mounted() {
    let userInfo = this.userInfo
    // 构建获取消息列表基础参数
    this.lists.queryBase = [
      userInfo.schoolid,
      userInfo.departmentid,
      userInfo.experimentids,
      userInfo.rolename === 'teacher' ? "1" : "2"
    ]
    // 获取列表
    this.ListMethods().getList(1, this.lists.pages.size, this.lists.query)
  },
  methods: {
    // 列表Methods
    ListMethods() {
      let $this = this
      return {
        // 获取列表
        async getList(page, size) {
          $this.lists.loading = true;
          let query = $this.lists.queryBase;
          let data = await MessageModel.getPageList(PLATFORM_ID, page, size, $this.userInfo.unionuserid, query);
          [$this.lists.list, $this.lists.pages] = data[0]
          $this.notReadNum = data[1]
          $this.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.lists.pages.size, $this.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.lists.pages.number, size, $this.lists.query)
        },
        clickViewBtn(entity,$index) {
          $this.entityInfo.dialog = true
          $this.entityInfo.info = entity
          if (!entity.hasRead) {
            MessageModel.setMessageRead(entity.messageId, $this.userInfo.unionuserid)
            $this.notReadNum -= 1
            $this.$set($this.lists.list[$index],"hasRead",true)
          }
        }
      }
    },
  }
}
</script>

<style>
.message-page .el-tabs__item {
  height: 30px;
  line-height: 30px;
}

.message-page .el-tabs__nav-wrap {
  overflow: visible;
}

.message-page .el-tabs__nav-scroll {
  overflow: visible;
}

.message-page .el-tabs__nav {
  float: none;
}
</style>
<style scoped lang="less">
.li {
  padding: 20px;
  border: 1px solid #cecece;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    background-color: #e6f7ff;
    border-color: #1890ff;
  }

  .title {
    color: #333;
    max-width: 800px;
  }

  .info {
    margin-top: 15px;
    font-size: 14px;
    color: #999;

    .type {
      margin-right: 10px;
    }

    .divider {
      margin-right: 10px;
    }
  }
}
</style>